import React from 'react'
import './portfolio.css'
import PORTFOLIO1 from '../../assets/portfolio1.png'
import PORTFOLIO4 from '../../assets/portfolio4.png'
import PORTFOLIO5 from '../../assets/portfolio5.jpeg'
import HTML from '../../assets/html.png'
import CSS from '../../assets/css.png'
import JS from '../../assets/js.png'
import REACT from '../../assets/react.png'
import VUE from '../../assets/vue.png'
import NEXT from '../../assets/next.png'
import MONGODB from '../../assets/mongodb.png'
import AWS from '../../assets/aws.png'
import FIREBASE from '../../assets/firebase.png'
import GCD from '../../assets/gcd.png'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Notable Projects</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        <div className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={PORTFOLIO4} className="portfolio__item-images"/>
            <h3>SummAIze</h3>
            <h5>Developed a dynamic Web Application leveraging Next.js, Google Cloud (GCD), and MongoDB to optimize student learning processes, enhancing efficiency and accessibility. Utilised Google Cloud OCR and Speech to Text to consolidate lecture notes and videos.</h5>
          </div>
          <div className='icon__box'>
            <img src={HTML} className="icon"/>
            <img src={CSS} className="icon"/>
            <img src={NEXT} className="icon"/>
            <img src={GCD} className="icon"/>
            <img src={MONGODB} className="icon"/>
          </div>
          <div className="portfolio__item-cta">
            <a href="https://github.com/kanetan4/Summaize" target = 'blank' className='btn'>Github</a>
            <a href='https://summaize-weikangg.vercel.app/' target = 'blank' className='btn btn-primary'>Live Demo</a>
          </div>
        </div>
        <div className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={PORTFOLIO5} className="portfolio__item-images"/>
            <h3>Ascendo</h3>
            <h5>Created an interactive mobile app, improving workplace productivity by gamifying employee workplace experience. Implemented using React Native and AWS (Amplify, API Gateway, Lambda, S3, DynamoDB, Cognito) as a Microservice Architecture.</h5>
          </div>
          <div className='icon__box'>
            <img src={HTML} className="icon"/>
            <img src={CSS} className="icon"/>
            <img src={REACT} className="icon"/>
            <img src={AWS} className="icon"/>
          </div>
          <div className="portfolio__item-cta">
            <a href='https://github.com/kanetan4/Ascendo' target = 'blank' className='btn'>Github</a>
            <a href='https://github.com/kanetan4/Ascendo' target = 'blank' className='btn btn-primary'>Live Demo</a>
          </div>
        </div>
        <div className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={PORTFOLIO1} className="portfolio__item-images"/>
            <h3>HomelyHub</h3>
            <h5>Designed and developed a Web Application to facilitate the sale of HDB flats, utilizing HTML, CSS, and Vue.js, including a price comparison feature, recommendation system and price prediction for similar listings. Data stored in Firebase and hosted on Firebase.</h5>
          </div>
          <div className='icon__box'>
            <img src={HTML} className="icon"/>
            <img src={CSS} className="icon"/>
            <img src={VUE} className="icon"/>
            <img src={FIREBASE} className="icon"/>
          </div>
          <div className="portfolio__item-cta">
            <a href="https://github.com/kanetan4/sc2006-project" target = 'blank' className='btn'>Github</a>
            <a href='https://sc2006-project-ef522.firebaseapp.com/' target = 'blank' className='btn btn-primary'>Live Demo</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Portfolio