import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>KANE</a>
      <ul className='permalinks'>
        <li><a href="#">HOME</a></li>
        <li><a href="#about">ABOUT</a></li>
        <li><a href="#experience">TECH STACK</a></li>
        <li><a href="#work">WORK</a></li>
        <li><a href="#portfolio">PORTFOLIO</a></li>
        <li><a href="#testimonials">TESTIMONIALS</a></li>
        <li><a href="#contact">CONTACT</a></li>
      </ul>
      <div className='footer__socials'>
        <a href='https://www.linkedin.com/in/tanyueyangkane/' target = 'blank'><BsLinkedin/></a>
        <a href='https://github.com/kanetan4' target = 'blank'><BsGithub/></a>
        <a href='https://instagram.com/kanethecocaine' target = 'blank'><BsInstagram/></a>
      </div>
    </footer>
    
  )
}

export default Footer