import React from 'react'
import './header.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'
import 'animate.css'
import TEST2 from '../../assets/test2.jpeg'

const Header = () => {
  const containerStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(31,31,56,1)), url(${TEST2})`,
    backdropFilter: 'blur(5px)',
  };

  return (
    <header style={containerStyle}>
      <div className="container header__container" >
        <h5>Hello I am</h5>
        <h1 className='animate__animated animate__backInDown' >Tan Yue Yang Kane</h1>
        <h5>Fullstack Developer</h5>
        <CTA/>
        <HeaderSocials/>

        <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header