import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/tanyueyangkane/' target = 'blank'><BsLinkedin/></a>
        <a href='https://github.com/kanetan4' target = 'blank'><BsGithub/></a>
        <a href='https://instagram.com/kanethecocaine' target = 'blank'><BsInstagram/></a>
    </div>
  )
}

export default HeaderSocials