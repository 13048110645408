import React from 'react'
import './experience.css'
import HTML from '../../assets/html.png'
import CSS from '../../assets/css.png'
import JS from '../../assets/js.png'
import REACT from '../../assets/react.png'
import VUE from '../../assets/vue.png'
import JAVA from '../../assets/java.png'
import PYTHON from '../../assets/python.png'
import C from '../../assets/c.png'
import C2 from '../../assets/c2.png'
import NODE from '../../assets/node.png'
import MYSQL from '../../assets/mysql.png'
import MONGODB from '../../assets/mongodb.png'
import R from '../../assets/r.png'
import AWS from '../../assets/aws.png'
import FIREBASE from '../../assets/firebase.png'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>My Skills</h5>
      <h2 className='myexp'>Tech Stack</h2>
      <div className='outer__container'>
        <div className='experience__container'>
          <div className='experience__box'>
            <img src={HTML} className="experience__img"/>
            <p>HTML</p>
          </div>
          <div className='experience__box'>
            <img src={CSS} className="experience__img"/>
            <p>CSS</p>
          </div>
          <div className='experience__box'>
            <img src={JS} className="experience__img"/>
            <p>Javascript</p>
          </div>
          <div className='experience__box'>
            <img src={VUE} className="experience__img"/>
            <p>Vue.js</p>
          </div>
          <div className='experience__box'>
            <img src={REACT} className="experience__img"/>
            <p>React.js</p>
          </div>
          <div className='experience__box'>
            <img src={NODE} className="experience__img"/>
            <p>Node.js</p>
          </div>
          <div className='experience__box'>
            <img src={AWS} className="experience__img"/>
            <p>AWS</p>
          </div>
          <div className='experience__box'>
            <img src={FIREBASE} className="experience__img"/>
            <p>Firebase</p>
          </div>
          <div className='experience__box'>
            <img src={JAVA} className="experience__img"/>
            <p>Java</p>
          </div>
          <div className='experience__box'>
            <img src={PYTHON} className="experience__img"/>
            <p>Python</p>
          </div>
          <div className='experience__box'>
            <img src={C} className="experience__img"/>
            <p>C</p>
          </div>
          <div className='experience__box'>
            <img src={C2} className="experience__img"/>
            <p>C++</p>
          </div>
          <div className='experience__box'>
            <img src={MYSQL} className="experience__img"/>
            <p>MySQL</p>
          </div>
          <div className='experience__box'>
            <img src={MONGODB} className="experience__img"/>
            <p>MongoDB</p>
          </div>
          
          <div className='experience__box'>
            <img src={R} className="experience__img"/>
            <p>R</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience