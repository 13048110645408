import React from 'react'
import './testimonials.css'
import JAY from '../../assets/jay.png'
import { Pagination, Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

const data = [
  {
    avatar: JAY,
    linkedin:"https://www.linkedin.com/in/jay-wang-94a477b3/",
    name: "Jay Wang",
    position: "Principal Consultant",
    company: "ITLink Business Solutions",
    review:"Kane exhibited quality traits that would make him a valuable team member in any company he joins. While at ITLink Business Solutions, Kane worked freely as an individual but also responsibly as a team player. Kane takes initiatives to provide regular updates on his progress in testing processes and is always receptive to feedback.",
  },
]

const Testimonials = () => {
  return (
    <div>
      <section id='testimonials'>
        <h5>Review from Clients and Colleagues</h5>
        <h2>Testimonials</h2>

        <Swiper className="container testimonials__container"
          modules={[Pagination, Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          >
          {
            data.map(({avatar,linkedin,name,position,company,review}, index) => {
              return (
                <SwiperSlide key = {index} className='testimonial'>
                  <div className='client__avatar'>
                    <img src={avatar} alt="" />
                  </div>
                  <a href={linkedin} target="_blank" className='client__name'>
                    {name}
                  </a>
                  <h3>{position}</h3>
                  <h3>{company}</h3>
                  <small className='client__review'>{review}</small>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </section>
    </div>
  )
}

export default Testimonials