import React from 'react'
import './about.css'
import ME from '../../assets/Main.jpeg'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about' style={{backgroundColor:''}}>
      <h5>Get To Know</h5>
      <h2 className='aboutme'>About Me</h2>
      
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        
        <div className="about__content">
          <div>
            <div className='about__achievements'>
              <FaAward className='about__icon'/>
              <h2 style={{fontSize:'25px'}}>4 Professional Internships</h2>
            </div>
            <div className='about__achievements'>
              <VscFolderLibrary className='about__icon'/>
              <h2 style={{fontSize:'25px'}}>10+ Completed Projects</h2>
            </div>
          </div>
            I am a Penultimate Computer Science & Business Analytics Student from NTU.
            
            My interests lies in Web Development and have been actively pursuing different projects and internships in the past 3 years. 
            
            I have been dancing for over 6 years and am an active member in various dance societies.

            Feel free to reach out regarding any employment opportunities as well as for a quick chat.
        </div>
      </div>
    </section>
  )
}

export default About