import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './work.css';
import HTML from '../../assets/html.png'
import CSS from '../../assets/css.png'
import JS from '../../assets/js.png'
import PYTHON from '../../assets/python.png'
import C2 from '../../assets/c2.png'
import TM1 from '../../assets/tm1.png'
import EXCEL from '../../assets/excel.png'
import WORD from '../../assets/word.png'
import NODE from '../../assets/node.png'
import DELUGE from '../../assets/deluge.png'
import CODIGO from '../../assets/codigo.jpeg'
import ITLINK from '../../assets/itlink.jpeg'
import THALES from '../../assets/thales.jpeg'
import GRAB from '../../assets/grab.jpeg'


export default function Work() {
  return (
    <section id='work'>
        <h5>Past Internship Experiences</h5>
        <h2>Work</h2>
        <div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(77,181,255)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(77,181,255)' }}
                    date="Sept 2023 - Present"
                    iconStyle={{ background: 'rgb(77,181,255)', color: '#fff'}}
                >
                    <h3 className="work__title">
                        Software Engineering Intern
                        
                    </h3>
                    <a href='https://www.grab.com' className='work__company' target="_blank" style = {{display:"flex", alignItems:"center", gap:"1rem"}}>
                        <img src = {GRAB} style={{height: "50px", width: "auto", borderRadius:"8px"}}></img> 
                        Grab 
                    </a>
                    <p>
                    •	Spearheaded the implementation of long needed features in the fleet management system as the only software engineer in the GrabRentals team.
                    </p>
                    <p>
                    •	Decoupled poorly linked systems to improve the workflow of fleet movement in Deluge script, JavaScript and Node.js.
                    </p>
                    <div style = {{marginTop: "10px", display:"flex", gap:"1rem"}}>
                        <img src={HTML} className = "work__icons"/>
                        <img src={CSS} className = "work__icons"/>
                        <img src={JS} className = "work__icons"/>
                        <img src={NODE} className = "work__icons"/>
                        <img src={DELUGE} className = "work__icons"/>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(77,181,255)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(77,181,255)' }}
                    date="May - Aug 2023"
                    iconStyle={{ background: 'rgb(77,181,255)', color: '#fff'}}
                >
                    <h3 className="work__title">Software Engineering Intern</h3>
                    <a href='https://www.thalesgroup.com/en' className='work__company' target="_blank" style = {{display:"flex", alignItems:"center", gap:"1rem"}}>
                        <img src = {THALES} style={{height: "50px", width: "auto", borderRadius:"8px"}}></img>
                        Thales Group
                    </a>
                    <p>
                    •	Engineered new features for proprietary applications in C++ and Python that processes personal profile information in XML that is then stored in physical chips. 
                    </p>
                    <p>
                    •	Implemented web app with HTML and D3.js to visualize JSON data and provided insights for higher level management. 
                    </p>
                    <p>
                    •	Performed static code analysis with external software to refactor legacy coding practices and utilized profiling tools to increase code efficiency.
                    </p>
                    <div style = {{marginTop: "10px", display:"flex", gap:"1rem"}}>
                        <img src={PYTHON} className = "work__icons"/>
                        <img src={C2} className = "work__icons"/>
                        <img src={HTML} className = "work__icons"/>
                        <img src={CSS} className = "work__icons"/>
                        <img src={JS} className = "work__icons"/>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(77,181,255)', color: '#fff' }}
                    date="May - July 2022"
                    iconStyle={{ background: 'rgb(77,181,255)', color: '#fff' }}
                >
                    <h3 className="work__title">Technical Consultant Intern</h3>
                    <a href='https://itlink.com.sg/' className='work__company' target="_blank" style = {{display:"flex", alignItems:"center", gap:"1rem"}}>
                        <img src = {ITLINK} style={{height: "50px", width: "auto", borderRadius:"8px"}}></img>
                        ITLink Business Solutions
                    </a>
                    <p>
                    •	Optimized databases using TM1 for client usage, ensuring optimal performance and reliability.
                    </p>
                    <p>
                    •	Utilized IBM TM1 Planning Analytics software to generate highly effective Microsoft Excel sheets, resulting in improved data analysis and decision-making capabilities for clients.
                    </p>
                    <p>
                    •	Spearheaded integration of data analytics and visualization via TM1PY and version control for TM1 software.
                    </p>
                    <div style = {{marginTop: "10px", display:"flex", gap:"1rem"}}>
                        <img src={PYTHON} className = "work__icons"/>
                        <img src={TM1} className = "work__icons"/>
                        <img src={EXCEL} className = "work__icons"/>
                        <img src={WORD} className = "work__icons"/>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(77,181,255)', color: '#fff' }}
                    date="March - July 2021"
                    iconStyle={{ background: 'rgb(77,181,255)', color: '#fff' }}
                >
                    <h3 className="work__title">Project Management Intern</h3>
                    <a href='https://www.codigo.co/' className='work__company' target="_blank" style = {{display:"flex", alignItems:"center", gap:"1rem"}}>
                        <img src = {CODIGO} style={{height: "50px", width: "auto", borderRadius:"8px"}}></img>
                        Codigo SG
                    </a>
                    <p>
                    •	Managed multiple projects for development of functional IOS and Android applications, as well as websites, ensuring timely delivery of high-quality products to clients.
                    </p>
                    <p>
                    •	Coordinated project process with Clients, Designers, Frontend and Backend Developers, providing clear guidance and direction to ensure optimal performance and productivity.
                    </p>
                    <div style = {{marginTop: "10px", display:"flex", gap:"1rem"}}>
                        <img src={EXCEL} className = "work__icons"/>
                        <img src={WORD} className = "work__icons"/>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(77,181,255)', color: '#fff' }}
                />
            </VerticalTimeline>
        </div>
    </section>
  )
}
